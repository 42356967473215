import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

let config = {
  apiKey: "AIzaSyDdO_hcKNLYhZqFhxfrZMf6FhsirAYf6fY",
  authDomain: "sym-timetracker.firebaseapp.com",
  databaseURL: "https://sym-timetracker-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sym-timetracker",
  storageBucket: "sym-timetracker.appspot.com",
  messagingSenderId: "35026733746",
  appId: "1:35026733746:web:80037d5182bf7abeff17ab",
  measurementId: "G-FBBL3DRR0X"
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(config)
}

export const database = firebase.database()
export const auth = firebase.auth()
